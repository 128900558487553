.ui.container.fluid.orderBar {
  background-color: #f2f2f2;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.orderBarSegment {
  display: inline-block;
  width: 100%;
  font-size: 1.1rem;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  text-align: right;
}

.orderBarSegment span {
  vertical-align: middle;
}

.orderBar .ui.button.plainOrderButton {
  margin-right: 1px;
}

.orderInfoText {
  margin-right: 1em;
}
