.ui.input.productSearch input::-moz-placeholder {
  color: rgba(0,0,0,.87);
}

.ui.input.productSearch input::-webkit-input-placeholder {
  color: #878787;
}

.ui.input.productSearch input::-ms-input-placeholder {
  color: rgba(0,0,0,.87);
}
