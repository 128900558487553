.ui.basic.segment.products {
  padding: 0;
  margin: 0;
}

.ui.basic.segment.categoriesLoader {
  margin-top: 14px;
  margin-bottom: 14px;
}

.ui.active.loader.products {
  margin-top: 14px;
}

.ui.divider.filtersDivider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ui.icon.info.message.inactiveUserMessage:first-child {
  margin-top: 1rem;
  margin-bottom: 0em;
}
