.ui.segments.printableOrderSegmentGroup {
  padding: 2rem;
  border: none;
  box-shadow: none;
  display: none !important;
}

@media print {
  .ui.segments.printableOrderSegmentGroup {
    display: block !important;
  }
}

.ui.segment.printableOrderAddress {
  padding-top: 0;
  margin-top: 0;
}
