.newOrderProductsList .productInBasketEdit {
  z-index: 100;
}

.newOrderProductsList .ui.form.productInBasketEdit input {
  text-align: center;
}

.newOrderProductsList .productInBasketEdit .noPostPackageDelivery {
  display: none;
}

.orderProductItemHeader a {
  color: rgba(0,0,0,.85);
}

.ui.header.orderProductItemHeader {
  margin-bottom: 0;
}

.newOrderProductsList .ui.form.productInBasketEdit .field .input {
  width: 120px;
}

.newOrderProductsList .ui.grid {
  margin: 0;
}
