.ui.basic.segment.thumbnailSegment {
  padding-top: 2rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.75rem;
  margin-top: 0;
}

.productThumbnail {
  height: 200px;
  width: auto;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.productCard .ui.dimmer {
  padding: 0;
}

.productCard .ui.dimmer.active {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.productCard .content {
  width: 100%;
}

.ui.top.attached.label:first-child + :not(.attached).productThumbnail {
  margin-top: 0 !important;
}

.productCard .ui.top.attached.label:first-child + :not(.attached).ui.header {
  margin-top: 1.25rem !important;
}

.productThumbnail:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.productThumbnail img {
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
}

.productCard .form.productInBasketEdit .field input {
  text-align: center;
}

.productCard .form.productInBasketEdit .field .ui.input {
  width: 50%;
}

.productCard a {
  color: rgba(0,0,0,.85);
}

.productCard .ui.header {
  margin-bottom: 0.6rem;
}

.totalPrice + .totalPrice {
  margin-top: 0.35rem;
}

.productCard .ui.basic.segment.buttonsSegment {
  margin-top: 0.85rem;
  padding-top: 0rem;
}

.productCard .productInBasketEdit {
  margin-bottom: 0.9rem;
}

.productCard .eight.wide.column.leftColumn {
  padding-right: 0.25rem;
  padding-left: 0.5rem;
}

.productCard .eight.wide.column.rightColumn {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}

.ui.label.topInfoLabel {
  background: transparent;
}

.ui.label.topInfoLabel.unavailable {
  color: white;
  background-color: #D92C2B;
}

.ui.label.topInfoLabel.top.left {
  border-bottom-right-radius: 0;
}

.unavailable {
  color: #D92C2B;
}
