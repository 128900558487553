.productInBasketEdit .ui.compact.icon.left.attached.button.delete {
  margin-right: -2px;
}

.productInBasketEdit .ui.compact.icon.right.attached.button.add {
  margin-left: -2px;
}

.productInBasketEdit .field input {
  z-index: 1;
}

.productInBasketEdit .field .ui.icon.input i {
  z-index: 2;
}
