.bottomWarning {
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.ui.message.bottomWarning {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
