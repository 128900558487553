.item .ui.small.image.orderedProductItemImage img {
  width: auto;
  max-height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.orderTableRow:hover {
  cursor: pointer;
}

.ordersList {
  margin-top: 1rem;
}

.orderedProductItemLink:not(:hover) {
  color: rgba(0, 0, 0, 0.87);
}
