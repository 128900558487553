.productTableRow:hover {
  cursor: pointer;
}

.ui.grid.adminProductsBar {
  margin-bottom: 0rem;
}

.ui.grid.adminProductsBar .ui.button.filter {
  margin-bottom: 0;
}

.ui.grid.adminProductsBar .row:not(:first-child) {
  padding-top: 0;
}
