.ui.container.fluid.activeAnnouncementBar {
  background-color: #3d3e3f;
  margin: 0 !important;
}

.cssmarquee {
  font-size: 0.95rem;
  font-weight: 600;
  padding-top: 0.35em;
  padding-bottom: 0.35em;
  overflow: hidden;
}

.cssmarquee span {
  display: inline-block;
  font-size: 1.2em;
  color: whitesmoke;
  animation: cssmarquee 35s linear infinite;
  white-space: nowrap;
  min-width: 100%;
}

@keyframes cssmarquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}