.image-gallery-image {
  height: 400px;
  width: auto;
  white-space: nowrap;
  text-align: center;
}

.fullscreen .image-gallery-image {
  width: auto;
  height: 100%;
}

.image-gallery-image:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.image-gallery-image img {
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
