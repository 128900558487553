.customerLink:not(:hover) {
  color: rgba(0, 0, 0, 0.87);
}

.ui.items > .item .extra.orderedProductItemMoreExtra {
  margin-top: 0;
}

.ui.segment.basic.orderStateEdit {
  padding: 0;
}

.ui.label.orderedProductItemLabel {
  margin-left: 0;
}
