#topBar {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
}

#topBar.withBottomShadow {
  box-shadow: 0 0px 2px 0 #212121;
}

#topBar + .ui.container {
  margin-top: 1rem;
}
