.ui.segment.dropzone, .dropzone {
  height: 100%;
  min-height: 125px;
  min-width: 125px;
  cursor: pointer;
  margin: 0;
}

.ui.segment.dropzone {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
