.customerTableRow:hover {
  cursor: pointer;
}

.adminCustomersList {
  margin-top: 1rem;
}

.ui.grid.adminCustomersBar .row:not(:first-child) {
  padding-top: 0;
}
