.ui.segment.basic.productHeader {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
 }

.ui.segment.basic.productFlags {
  padding: 0;
  margin: 0;
}

.ui.segment.basic.productFlags.subflags:not(:empty) {
  margin-bottom: 1.75rem;
}

.ui.fluid.search .ui.icon.input {
  width: 100%;
}

.productSegment .ui.header {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.productSegment .basketSummary {
  margin-top: 2.5rem;
}

.productSegment .button.add, .productSegment .button.delete {
  margin-top: 0.5rem;
}

.ui.message.productSegment {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 1.5rem;
}

.productSegment .priceText {
  color: rgba(0,0,0,.87);
  font-size: 1.5rem;
  font-weight: bold;
}

.productSegment .priceText.promo {
  color: red;
}

p.hidden {
  visibility: hidden;
}

p.none {
  display: none;
}

.ui.search.productSearch input::-moz-placeholder {
  color: rgba(0,0,0,.87);
}

.ui.search.productSearch input::-webkit-input-placeholder {
  color: #878787;
}

.ui.search.productSearch input::-ms-input-placeholder {
  color: rgba(0,0,0,.87);
}

.icon.chevron.left {
  cursor: pointer;
}

.icon.chevron.left.back:not(:hover) {
  color: rgba(0, 0, 0, 0.87);
}

.ui.message.productSegment .header {
  margin-bottom: 1rem;
}
