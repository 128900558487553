.ui.label.attached.newLabel,
.ui.label.attached.priceLabel {
  font-size: 1em;
  padding: 0.5em !important;
}

.ui.label.left.bottom.attached.newLabel {
  border-radius: 0 !important;
}

.ui.label.newLabel {
  color: white;
  background-color: rgb(208, 25, 25, 0.9);
}

.ui.basic.label.newLabel {
  color: #db2828;
  border-width: 0;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1.1em;
}

.ui.label.priceLabel {
  color: white;
  background-color: rgb(33, 133, 208, 0.9);
}

.ui.label.right.bottom.attached.priceLabel {
  border-radius: 0 !important;
}

.ui.label.priceLabel.promo {
  background-color: #db2828;
}

.ui.basic.label.priceLabel {
  color: rgb(33, 133, 208);
  border-width: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.ui.basic.label.priceLabel.promo {
  color: #db2828;
}
