.ui.segments.opaqueGroup {
  border: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
}

.ui.segment.basic.opaqueSegment {
  border: 0;
  padding: 0;
}