.ui.button.filter,
.ui.button.filter:focus {
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  text-transform: uppercase;
  border-radius: 0.5em;
  background-color: #434445;
  color: #e6e6e6;
}

.ui.button.active.filter {
  background-color: #e8e8e8;
  color: #434445;
}

.ui.button.filter:hover,
.ui.button.active.filter:hover {
  background-color: #e6e6e6;
  color: #434445;
}

.ui.small.active.compact.button.filter {
  padding-right: 0.7em;
}

.ui.small.active.compact.button.filter .close.icon.filter {
  margin-left: 0.45em;
  margin-right: 0;
}

.ui.segment.filters {
  padding: 0;
  margin: 0;
}
