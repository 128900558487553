.categories .ui.input input, .categories .ui.card .content .header {
  text-align: center;
  padding: .67857143em 0em;
  font-size: 1.2em;
  font-weight: 700;
}

.ui.cards.categories > .card > .content > .header:not(.ui) {
  margin-top: 0;
}

.ui.card.category .content .meta {
  color: #d01919;
}
