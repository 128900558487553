.ui.container.fluid.productsSearchBar {
  background-color: #f2f2f2;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.productsSearchBar .ui.segment {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.productsSearchBar {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.productsSearchBar.topPadding {
  padding-top: 0.5em;
}

.withOrderBar + div > .productsSearchBar {
  padding-top: 0;
}
